import React from "react";
import loadable from "@loadable/component";
import { graphql } from "gatsby";

const Layout = loadable(() => import("@common/Layout/Layout"));
const About = loadable(() => import("@components/About/About"));
const SEO = loadable(() => import("@components/Common/SEO/SEO"));

const AboutPage = ({ path, data }) => (
    <>
        <SEO
            title="Our Experienced Offshore Software Development Team Ukraine"
            titleTemplate="Impressit"
            SEOData={data.allStrapiSeoDescriptions?.nodes}
            description="Behold the offshore software development team Ukraine who have a common goal of helping startups and SMEs build innovative digital products"
            path={path}
        />
        <Layout path={path} isNoPadding>
            <About />
        </Layout>
    </>
);

export default AboutPage;

export const aboutPageQuery = graphql`
    query aboutPageSEODataQuery {
        allStrapiSeoDescriptions {
            nodes {
                Title
                MetaTitle
                PageUrl
            }
        }
    }
`;
